import { Button } from '../../components/button/button';
import { IPage403 } from './page-403.types';

export const Page403 = ({ disableBackHomeButton }: IPage403) => {
  const goBackHome = () => {
    window.location.href = '/';
  };

  return (
    <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">You have no access</h1>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {!disableBackHomeButton && <Button onClick={goBackHome}>Go back home</Button>}
          <a href="mailto:support-psaccess@payersciences.com" className="text-sm font-semibold text-gray-900">
            Contact PS Access support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
};
