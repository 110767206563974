import { ReactNode } from 'react';
import { ReactRouterLink } from '../../helpers/react-router-dom.helpers';
import { classNames } from '../../helpers/classNames';

interface Props {
  to: string;
  children: ReactNode;
  className?: string;
}

export const Link = ({ children, to, className }: Props) => {
  return (
    <ReactRouterLink to={to} className={classNames('psc-link', className)}>
      {children}
    </ReactRouterLink>
  );
};
