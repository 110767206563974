import { Colors } from '../../types/colors.types';
import { AIM_APP_ICON, CHAT_APP_ICON, FUN_APP_ICON, PLATFORM_APP_ICON } from '../app-logo/app-logo.constants';
import { getNavAppUrl } from '../../../common/helpers/apps.helpers';
import { App } from '../../../common/types/app.types';

const logos: Record<App, string> = {
  aim: AIM_APP_ICON,
  platform: PLATFORM_APP_ICON,
  chat: CHAT_APP_ICON,
  fun: FUN_APP_ICON,
};

const appNames: Record<App, string> = {
  aim: 'AIM',
  fun: 'FUN',
  platform: 'PS Access',
  chat: 'Access Insights',
};

const appColors: Record<App, Colors> = {
  aim: 'orange',
  platform: 'orange',
  chat: 'lime',
  fun: 'lime',
};

export const getNavAppConfig = (app: App) => {
  return {
    logo: logos[app],
    url: getNavAppUrl(app),
    appName: appNames[app],
    appColor: appColors[app],
  };
};
