import { atom } from 'recoil';

export interface Notification {
  id: string;
  title: string;
  message?: string;
  type?: 'success' | 'error';
  closeAfter?: number | false;
}

interface Atom {
  notifications: Notification[];
}

export const NotificationAtom = atom<Atom>({
  key: 'notifications',
  default: {
    notifications: [],
  },
});
