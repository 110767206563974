import { ErrorResponse } from '../../server/helpers/error.helpers';
import { useMutation } from '@tanstack/react-query';
import { isErrorResponse, UseApiOnError, UseApiOnSuccess } from '../helpers/api.helpers';
import axios from 'axios';
import { isAxiosError } from 'axios';

export const usePostApi = <TInput, TOutput = undefined, TError = ErrorResponse>(
  name: string,
  options: {
    path: string;
    onSuccess?: UseApiOnSuccess<TOutput>;
    onError?: UseApiOnError<TError>;
  },
) => {
  return useMutation<TOutput | undefined, TError | undefined, TInput>({
    mutationKey: [name],
    mutationFn: async (data) => {
      let response: TOutput | undefined;

      try {
        const postResponse = await axios.post<any, { data: TOutput }, TInput>(options.path, data, {
          withCredentials: true,
        });

        response = postResponse.data;
      } catch (err: any) {
        if (isAxiosError(err) && err.response && isErrorResponse(err.response.data)) {
          throw err.response.data;
        }

        throw new ErrorResponse('Something went wrong', []);
      }

      return response;
    },
    onSuccess: options.onSuccess,
    onError: options.onError,
  });
};
