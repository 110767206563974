import { ErrorResponse } from '../../server/helpers/error.helpers';

export const getUrlWithQueryParams = (path: string, queryParams?: Record<string, unknown>) => {
  if (!queryParams) {
    return path;
  }

  const url = new URL(path);
  for (const [key, value] of Object.entries(queryParams)) {
    if (value) {
      url.searchParams.append(key, value.toString());
    }
  }
  return url.toString();
};
export type UseApiOnSuccess<T> = (data: T | undefined) => void;
export type UseApiOnError<TError> = (error: TError | undefined) => void;

export const isErrorResponse = (response: any): response is ErrorResponse => {
  return response?.message !== undefined;
};
