import { GetCurrentUserResponse } from '../../../platform/server/api/auth/get-current-user/get-current-user.types';
import { LowercaseFirstLetter, UppercaseFirstLetter } from '../types/text.types';

export const checkUserHasAnyRole = (user: GetCurrentUserResponse, roles: UppercaseFirstLetter[]) => {
  const userRoles = user.rolesAndPermissions?.map((role) => role.roleId) ?? [];
  return roles.some((role) => userRoles.includes(role));
};

export const checkUserHasAnyPermission = (user: GetCurrentUserResponse, permissions: LowercaseFirstLetter[]) => {
  const userPermissions = user.rolesAndPermissions?.map((role) => role.permissionId) ?? [];
  return permissions.some((permission) => userPermissions.includes(permission));
};
