import { lazy } from 'react';

export const DashboardPage = lazy(async () => {
  return import('./pages/dashboard.page').then((module) => ({
    default: module.DashboardPage,
  }));
});

export const Login = lazy(async () => {
  return import('./pages/auth/login/login.page').then((module) => ({
    default: module.LoginPage,
  }));
});

export const UsersAdminPage = lazy(async () => {
  return import('./pages/admin/users/users-admin.page').then((module) => ({
    default: module.UsersAdminPage,
  }));
});

export const UserAdminPage = lazy(async () => {
  return import('./pages/admin/users/user/user-admin.page').then((module) => ({
    default: module.UserAdminPage,
  }));
});

export const RolesAdminPage = lazy(async () => {
  return import('./pages/admin/roles/roles-admin.page').then((module) => ({
    default: module.RolesAdminPage,
  }));
});

export const ForgotPasswordPage = lazy(async () => {
  return import('./pages/auth/forgot-password/forgot-password.page').then((module) => ({
    default: module.ForgotPasswordPage,
  }));
});

export const ResetPasswordPage = lazy(async () => {
  return import('./pages/auth/reset-password/reset-password.page').then((module) => ({
    default: module.ResetPasswordPage,
  }));
});

export const UpdatePasswordPage = lazy(async () => {
  return import('./pages/auth/update-password/update-password.page').then((module) => ({
    default: module.UpdatePasswordPage,
  }));
});

export const RoleAdminPage = lazy(async () => {
  return import('./pages/admin/roles/role/role-admin.page').then((module) => ({
    default: module.RoleAdminPage,
  }));
});

export const BrandsAdminPage = lazy(async () => {
  return import('./pages/admin/brands/brands-admin.page').then((module) => ({
    default: module.BrandsAdminPage,
  }));
});

export const LogActivityAdminPage = lazy(async () => {
  return import('./pages/admin/log-activity/log-activity-admin.page').then((module) => ({
    default: module.LogActivityAdminPage,
  }));
});
