import { Express, NextFunction, Request, Response } from 'express';

export const ACCOUNT_EXISTS_ALREADY = 'Account exists already';
export const ACCOUNT_IS_LOCKED = 'Email is locked. Please contact the administrator';
export const ACCOUNT_IS_NOT_ACTIVE = 'Account is not active';
export const FILE_WITH_NO_EXTENSION = 'Uploaded file does not have an extension';
export const INVALID_CREDENTIALS = 'Email or password is incorrect';
export const INVALID_FILE_UPLOAD = 'Invalid file upload';
export const LOGIN_METHOD_NOT_SUPPORTED = 'This login method is not supported';
export const MISSING_STORAGE_CONFIGURATION = 'Storage configuration is missing in the server';
export const NOT_AUTHENTICATED = 'User is not authenticated';
export const OBJECT_NOT_FOUND = 'Object not found';
export const RESPONSE_ERROR = 'Error processing request';
export const UNAUTHORIZED_ACCESS = 'Unauthorized access';

export const ErrorResponseStatusCodes: { [errorMessage: string]: number } = {
  [ACCOUNT_EXISTS_ALREADY]: 409,
  [ACCOUNT_IS_LOCKED]: 400,
  [ACCOUNT_IS_NOT_ACTIVE]: 403,
  [FILE_WITH_NO_EXTENSION]: 400,
  [INVALID_CREDENTIALS]: 401,
  [INVALID_FILE_UPLOAD]: 400,
  [MISSING_STORAGE_CONFIGURATION]: 500,
  [NOT_AUTHENTICATED]: 401,
  [OBJECT_NOT_FOUND]: 404,
  [RESPONSE_ERROR]: 500,
  [UNAUTHORIZED_ACCESS]: 403,
};

export class ErrorResponse extends Error {
  constructor(
    message: string,
    public fields?: string[],
  ) {
    super(message);
  }
}

export const errorHandler = (error: any, req: Request, res: Response, _: NextFunction) => {
  if (error instanceof ErrorResponse) {
    const errorMessage = error.message;
    return res.status(ErrorResponseStatusCodes[errorMessage] || 400).json({
      message: error.message,
      fields: error.fields,
    });
  }

  console.error(error);

  return res.status(500).json({
    message: RESPONSE_ERROR,
    error,
  });
};

export const setupErrorHandler = (app: Express): void => {
  app.use(errorHandler);
};
