import { Alert } from '../alert/alert';

interface Props {
  message?: string;
}

export const ProtectedPageNotAuthorized = ({ message }: Props) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <Alert>{message || 'Not Authorized'}</Alert>
    </div>
  );
};
