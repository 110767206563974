import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { App } from '../../../../../shared/common/types/app.types';
import { AdminBreadcrumb } from '../../../../../shared/client/components/admin-breadcrumb/admin-breadcrumb';
import { AdminPageTitle } from '../../../../../shared/client/components/admin/admin-page-title';
import { AdminPageSection } from '../../../../../shared/client/components/admin/admin-page-section';
import { ImageUploader } from '../../../../../shared/client/components/image-uploader/image-uploader';
import { useGetPlatformConfig } from '../../../../../shared/client/hooks/use-get-platform-config.hook';
import { Code } from '../../../../../shared/client/components/code/code';

export const ConfigAdminPage = () => {
  const platformConfig = useGetPlatformConfig();

  return (
    <Fragment>
      <Helmet>
        <title>Configurations</title>
      </Helmet>
      <AdminBreadcrumb
        project={App.enum.platform}
        items={[
          {
            name: 'Configurations',
            active: true,
          },
        ]}
      />
      <AdminPageTitle>Configurations</AdminPageTitle>
      <AdminPageSection title="Images">
        <div className="flex flex-row">
          <ImageUploader
            className="flex-1 pr-3"
            fieldId="logoImage"
            fieldTitle="Logo Image"
            refetchImage={platformConfig.refetch}
            uploadedImageUrl={platformConfig.data?.logoUrl}
            urlToUpload="/api/do-upload-logo"
          />
          <ImageUploader
            className="flex-1 px-3"
            fieldId="slideCoverImage"
            fieldTitle="Slide Cover for AIM Report"
            refetchImage={platformConfig.refetch}
            uploadedImageUrl={platformConfig.data?.slideCoverUrl}
            urlToUpload="/api/do-upload-slide-cover"
          />
          <ImageUploader
            className="flex-1 pl-3"
            fieldId="slideTemplateImage"
            fieldTitle="Slide Template for AIM Report"
            refetchImage={platformConfig.refetch}
            uploadedImageUrl={platformConfig.data?.slideTemplateUrl}
            urlToUpload="/api/do-upload-slide-template"
          />
        </div>
      </AdminPageSection>
      <AdminPageSection title="Environment Variables">
        <Code>{JSON.stringify(platformConfig.data?.env, null, 2)}</Code>
      </AdminPageSection>
    </Fragment>
  );
};
