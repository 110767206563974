import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

interface Props {
  href: string;
  children: ReactNode;
  className?: string;
  openInNewTab?: boolean;
}

export const LinkExternal = ({ openInNewTab, children, href, className }: Props) => {
  return (
    <a
      href={href}
      className={classNames('psc-link', className)}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  );
};
