import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Code = ({ children, className }: Props) => {
  return <pre className={classNames('w-full rounded bg-gray-800 p-4 text-xs text-white', className)}>{children}</pre>;
};
