import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';
import { getColorClassNames } from '../../helpers/colors.helpers';
import { Colors } from '../../types/colors.types';

interface Props {
  icon?: string;
  children: ReactNode;
  color: Colors;
}

export const AppLogo = ({ icon, children, color }: Props) => {
  const classNameColor = getColorClassNames(color);

  return (
    <div className={classNames('flex items-center rounded px-1.5 text-lg font-bold', classNameColor)}>
      {icon && <i className={classNames('mr-psc-xs text-2xl', icon)} />} {children}
    </div>
  );
};
