import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';
import { Button } from './button';
import { BaseButtonProps } from './button.types';

export const ButtonPrimary = ({
  animate,
  onClick,
  disabled,
  type,
  children,
  className,
  classNameRoundedStyle,
  ...buttonProps
}: BaseButtonProps) => {
  const config = getMultiClientPlatformConfigForClient();

  return (
    <Button
      {...buttonProps}
      type={type}
      animate={animate}
      disabled={disabled}
      className={className}
      classNameRoundedStyle={classNameRoundedStyle}
      classNameDisabledStyle="primary bg-gray-300 text-gray-500 focus:ring-gray-500"
      classNameStyle={
        config.buttonPrimaryClassName ||
        'primary default bg-green-700 text-white hover:bg-green-700 focus:ring-green-700'
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
