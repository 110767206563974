import { usePostApi } from './use-post-api.hook';

interface Props {
  redirectToLogin?: boolean;
}

export const useDoLogout = ({ redirectToLogin }: Props) => {
  return usePostApi('logout', {
    path: window.env.PLATFORM_URL + '/api/do-logout',
    onSuccess: () => {
      if (redirectToLogin) {
        window.location.href = '/login';
      } else {
        window.location.reload();
      }
    },
  });
};
