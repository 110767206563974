import { ReactNode } from 'react';
import { cn } from '../../helpers/classNames';

interface Props {
  htmlFor: string;
  className?: string;
  children: ReactNode;
}

export const FormLabel = ({ children, htmlFor, className }: Props) => {
  return (
    <label htmlFor={htmlFor} className={cn('psc-text block select-none pb-1 text-sm font-medium', className)}>
      {children}
    </label>
  );
};
