import { classNames } from '../../helpers/classNames';

interface Props {
  text?: string;
  inline?: boolean;
  className?: string;
}

export const Loading = ({ text, inline, className }: Props) => {
  return (
    <div
      className={classNames(
        inline ? 'inline-block' : 'fixed inset-0 z-50 flex items-center justify-center bg-white',
        className,
      )}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-t-2 border-gray-900"></div>
        <div className="mt-4 text-black">{text === undefined ? 'Loading...' : text}</div>
      </div>
    </div>
  );
};
