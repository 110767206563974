import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Page404 } from '../pages/page-404/page-404';

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <Page404 />;
  }

  console.error('error', error);

  const message = 'Something went wrong';

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <div className="flex flex-col items-center justify-center">
        <i className="fas fa-warning fa-xl text-red-500"></i>
        <div className="mt-4 text-black">{message}</div>
      </div>
    </div>
  );
};
