import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getUrlWithQueryParams, isErrorResponse } from '../helpers/api.helpers';
import { ErrorResponse } from '../../server/helpers/error.helpers';
import { isAxiosError } from 'axios';

export const useGetApi = <TOutput, TQueryParams extends Record<string, unknown> | undefined = undefined>(
  name: string | [string, TQueryParams],
  options: {
    retry?: number | boolean;
    path: string;
    enabled?: boolean;
    cacheTime?: number;
    refetchOnWindowFocus?: boolean;
  },
) => {
  const data = Array.isArray(name) ? name[1] : undefined;
  const url = getUrlWithQueryParams(options.path, data);

  return useQuery({
    queryKey: [url],
    queryFn: async ({ signal }) => {
      let response: { data: TOutput } | undefined;

      try {
        response = await axios.get<unknown, { data: TOutput }>(url, {
          withCredentials: true,
          signal,
        });
      } catch (err: any) {
        if (isAxiosError(err)) {
          if (err?.code === 'ERR_CANCELED') {
            return;
          }

          if (err && err.response && isErrorResponse(err.response.data)) {
            throw err.response.data;
          }
        }

        throw new ErrorResponse('Something went wrong', []);
      }

      return response?.data;
    },
    enabled: options.enabled,
    retry: options.retry !== undefined ? options.retry : true,
    refetchOnWindowFocus: options.refetchOnWindowFocus === undefined ? true : options.refetchOnWindowFocus,
  });
};
