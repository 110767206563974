import React, { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../helpers/classNames';

interface Props {
  items: ReactNode;
  className?: string;
  classNameWidth?: string;
  classNamePosition?: string;
  buttonRender: (open: boolean) => ReactNode;
}

export const DropdownMenu = ({ buttonRender, className, items, classNamePosition, classNameWidth }: Props) => {
  const onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  return (
    <Menu as="nav" className={classNames('relative select-none', className)}>
      {({ open }) => (
        <Fragment>
          <Menu.Button as="div" onClick={onClick}>
            {buttonRender(open)}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items>
              <div
                className={classNames(
                  'absolute z-50 overflow-hidden rounded border border-slate-200 bg-white shadow-lg',
                  classNamePosition ? classNamePosition : 'left-0 mt-5 origin-top-left',
                  classNameWidth ? classNameWidth : 'w-64',
                )}
              >
                {items}
              </div>
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  );
};
