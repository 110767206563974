import { Suspense } from 'react';
import { Loading } from '../../../shared/client/components/loading/loading';
import { Outlet } from 'react-router-dom';

export const AppNotLogged = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  );
};
