import { GetCurrentUserResponse } from '../../../../platform/server/api/auth/get-current-user/get-current-user.types';

interface Props {
  user: GetCurrentUserResponse;
}

export const NavUserInfo = ({ user }: Props) => {
  return (
    <div>
      <div className="pl-psc-base pt-psc-base text-xs font-medium uppercase leading-none text-gray-800">Account</div>
      <div className="select-none py-psc-sm pl-psc-base pr-psc-base text-xs">
        <div>{user.fullName}</div>
        <div className="truncate text-gray-600">{user.email}</div>
      </div>
    </div>
  );
};
