import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { router } from '../router';

import { RouterProvider } from '../../../shared/client/helpers/react-router-dom.helpers';
import { RecoilRoot } from 'recoil';
import { ErrorBoundary } from '../../../shared/client/components/error-boundary/error-boundary';

export const Wrapper = () => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <StrictMode>
      <ErrorBoundary>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <RouterProvider router={router} />
            </HelmetProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </StrictMode>
  );
};
