import { usePostApi } from './use-post-api.hook';

interface Props {
  onError?: () => void;
  onSuccess?: () => void;
}

export const useDoContactSupport = ({ onError, onSuccess }: Props) => {
  return usePostApi('contact-support', {
    path: window.env.PLATFORM_URL + '/api/do-contact-support',
    onSuccess: () => {
      onSuccess?.();
    },
    onError: () => {
      onError?.();
    },
  });
};
