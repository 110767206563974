import { Fragment, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { ProtectedPageNotAuthorized } from './protected-page-not-authorized';
import { checkUserHasAnyPermission, checkUserHasAnyRole } from '../../../common/helpers/auth.helpers';
import { LowercaseFirstLetter, UppercaseFirstLetter } from '../../../common/types/text.types';

interface Props {
  children: ReactNode;
  roles?: UppercaseFirstLetter[];
  permissions?: LowercaseFirstLetter[];
}

export const ProtectedPage = ({ children, roles, permissions }: Props) => {
  const currentUser = useRecoilValue(CurrentUserAtom);

  if (!currentUser.isAuthenticated) {
    return <ProtectedPageNotAuthorized message="Not Authenticated" />;
  }

  if (roles && !checkUserHasAnyRole(currentUser, roles)) {
    return <ProtectedPageNotAuthorized />;
  }

  if (permissions && !checkUserHasAnyPermission(currentUser, permissions)) {
    return <ProtectedPageNotAuthorized />;
  }

  return <Fragment>{children}</Fragment>;
};
