export const GA_USER_DATA_EVENT = 'user_data_available';
export const GA_USER_LOGIN_EVENT = 'user_log_in';
export const GA_LOGIN_RECOVERY_SENT_EVENT = 'login-recovery-link-resend';
export const GA_UPDATE_PASSWORD_CONFIRMED_EVENT = 'update-password-confirmed';

interface DataLayerArgs {
  dataLayer: any;
}

export const registerDataLayer = (dataLayerObject: DataLayerArgs) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push(dataLayerObject.dataLayer);
};
