import { useSetRecoilState } from 'recoil';
import { Notification, NotificationAtom } from '../atoms/notification.atom';

export const useAddNotification = () => {
  const setNotifications = useSetRecoilState(NotificationAtom);
  return (notification: Omit<Notification, 'id'>) => {
    const id = Date.now().toString();
    const closeAfter = notification.closeAfter ?? 5000;

    setNotifications((prev) => ({
      ...prev,
      notifications: [...prev.notifications, { ...notification, id }],
    }));

    if (closeAfter) {
      setTimeout(() => {
        setNotifications((prev) => ({
          ...prev,
          notifications: prev.notifications.filter((notification) => notification.id !== id),
        }));
      }, closeAfter);
    }
  };
};
