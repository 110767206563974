import { useCallback, useState } from 'react';

import { ContactSupportModalProps } from './ContactSupportModal.types';
import { Modal } from '../modal/modal';
import { FormRow } from '../form/form-row';
import { FormLabel } from '../form/form-label';
import { FormActions } from '../form/form-actions';
import { Form } from '../form/form';
import { FormInput } from '../form/form-input';
import { FormTextArea } from '../form/form-text-area';
import { Button } from '../button/button';
import { ButtonPrimary } from '../button/button-primary';
import { useAddNotification } from '../../hooks/use-add-notification.hook';
import { useDoContactSupport } from '../../hooks/use-do-contact-support.hook';
import { ButtonUnderline } from '../button/button-underline';
import { isEmailValid } from '../../../common/helpers/string.helpers';

const SUPPORT_EMAIL = window.env.PS_ACCESS_SUPPORT_EMAIL;

export const ContactSupportModal = ({ open, setOpen }: ContactSupportModalProps) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const addNotification = useAddNotification();

  const onClose = useCallback(() => {
    setEmail('');
    setMessage('');
    setOpen(false);
  }, [setOpen]);

  const doContactSupport = useDoContactSupport({
    onSuccess: () => {
      setSendingMessage(false);
      addNotification({
        type: 'success',
        title: '',
        message: 'Email sent successfully',
        closeAfter: 2000,
      });
      onClose();
    },
    onError: () => {
      setSendingMessage(false);
      addNotification({
        type: 'error',
        title: '',
        message: 'Email could not be sent. Please try again or send through your email provider.',
        closeAfter: 5000,
      });
    },
  });

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL);
    addNotification({
      type: 'success',
      title: '',
      message: 'Email copied to clipboard',
      closeAfter: 2000,
    });
  };

  const sendEmail = () => {
    if (!isEmailValid(email)) {
      addNotification({
        type: 'error',
        title: '',
        message: 'Email is invalid. Please check your email and try again.',
        closeAfter: 5000,
      });
      return setInvalidEmail(true);
    }

    setSendingMessage(true);
    doContactSupport.mutate({
      email,
      message,
    });
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Form className={'text-sm font-semibold'} id="new-report" onSubmit={sendEmail}>
        <FormRow>
          <span>For all technical issues or questions, email us at:</span>
        </FormRow>
        <FormRow>
          <a className={'text-blue-600 underline hover:text-blue-800'} href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </a>
        </FormRow>
        <FormRow>
          <Button onClick={copyEmailToClipboard}>Copy Email</Button>
        </FormRow>
        <FormRow>
          <span>You can also use the form below:</span>
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="email">Your Email</FormLabel>
          <FormInput
            fullWidth
            id="email"
            name="email"
            className={'font-normal'}
            value={email}
            hasError={invalidEmail}
            onChange={(event) => {
              setInvalidEmail(false);
              setEmail(event.target.value);
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="message">Message</FormLabel>
          <FormTextArea
            id="message"
            name="message"
            className={'font-normal'}
            rows={10}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </FormRow>
        <FormActions>
          <div className={'flex-1'}></div>
          <ButtonUnderline className={'mr-5 mt-psc-base px-3'} onClick={onClose}>
            Cancel
          </ButtonUnderline>
          <ButtonPrimary className="mt-psc-base justify-center" type="submit" disabled={sendingMessage}>
            Send
          </ButtonPrimary>
        </FormActions>
      </Form>
    </Modal>
  );
};
