import {
  MemoryRouter as componentMemoryRouter,
  RouterProvider as componentRouterProvider,
  createBrowserRouter as functionCreateBrowserRouter,
  Link,
} from 'react-router-dom';

export const createBrowserRouter = functionCreateBrowserRouter;

export const ReactRouterLink = Link;
export const MemoryRouter = componentMemoryRouter;
export const RouterProvider = componentRouterProvider;
