import { getFirstItem } from './array.helpers';

export const getUniqStringsIfValidString = (list: unknown[]): string[] => {
  const newList = list.filter(isString);
  return [...new Set(newList)];
};

export const isString = (value: unknown): value is string => typeof value === 'string';

export const getCroppedText = (text: string, maxLength: number, ellipsis = '...'): string => {
  if (text.length <= maxLength) {
    return text;
  }

  let newText = `${text.slice(0, maxLength - ellipsis.length)}${ellipsis}`;

  if (newText.length > maxLength) {
    newText = newText.slice(0, maxLength);
  }

  return newText;
};

export const getCroppedTextWithCount = (
  text: string,
  maxLength: number,
  existingCroppedTexts: string[],
  ellipsis = '...',
): string => {
  const croppedText = getCroppedText(text, maxLength, ellipsis);

  if (existingCroppedTexts.length === 0 || !existingCroppedTexts.includes(croppedText)) {
    return croppedText;
  }

  const croppedTextWithoutEllipsis = croppedText.replace(ellipsis, '');

  const maxCount = existingCroppedTexts.reduce((acc, curr) => {
    const count = Number(getFirstItem(curr.match(/\d+/g) || undefined, 0));
    return count > acc ? count : acc;
  }, 0);
  const countText = ` (${maxCount + 1})`;
  return `${getCroppedText(croppedTextWithoutEllipsis, maxLength - countText.length, ellipsis)}${countText}`;
};

export const isEmailValid = (value: string): boolean => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const matches = value.match(validRegex) || [];
  return matches.length > 0;
};

export const capitalizeText = (text: string): string => {
  if (!text) return text;

  const words = text.split(' ');

  const capitalizedWords = words.map((word) => {
    if (word.length === 0) return word; // Handle empty words
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(' ');
};

export const getPunctuationAtEnd = (word: string): string | undefined => {
  // Use a regular expression to match punctuation at the end of a word
  const match = word.match(/\w+([.,;?!])$/);

  // Check if there is a match and return the punctuation
  return match ? match[1] : undefined;
};
