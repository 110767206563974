import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

interface Props {
  className?: string;
  classNameSpacing?: string;
  children: ReactNode;
}

export const AdminPageTitle = ({ children, className, classNameSpacing = 'mb-psc-lg' }: Props) => {
  return <div className={classNames(className, 'psc-h1', classNameSpacing)}>{children}</div>;
};
