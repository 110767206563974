import { BaseButtonProps } from './button.types';
import { Button } from './button';

export const ButtonUnderline = ({ children, ...buttonProps }: BaseButtonProps) => {
  return (
    <Button
      {...buttonProps}
      classNameStyle="primary default underline bg-white text-gray-800 hover:bg-gray-100 focus:ring-1 focus:ring-blue-700 shadow-none"
      classNameDisabledStyle="primary rounded-full bg-gray-300 text-gray-500 focus:ring-gray-500"
    >
      {children}
    </Button>
  );
};
