import '../../shared/common/global-imports';

import { createRoot } from 'react-dom/client';
import { Wrapper } from './components/wrapper';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<Wrapper />);
}
