import { classNames } from '../../helpers/classNames';

interface Props {
  className?: string;
}

export const NavBackIconLink = ({ className }: Props) => {
  return (
    <a className={classNames('block leading-none text-gray-800', className)} href={window.env.PLATFORM_URL}>
      <i className="fas fa-circle-chevron-left text-2xl" />
    </a>
  );
};
