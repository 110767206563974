import { Button } from '../../components/button/button';
import { IPage404 } from './page-404.types';

export const Page404 = ({ disableBackHomeButton }: IPage404) => {
  const goBackHome = () => {
    window.location.href = '/';
  };

  return (
    <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {!disableBackHomeButton && <Button onClick={goBackHome}>Go back home</Button>}
          <a href="mailto:support-psaccess@payersciences.com" className="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
};
