import { Colors } from '../types/colors.types';
import { classNames } from './classNames';

export const getColorClassNames = (color: Colors) => {
  return classNames(
    color === 'blue' && 'text-blue-800 hover:text-blue-700',
    color === 'orange' && 'text-orange-600 hover:text-orange-500',
    color === 'violet' && 'text-violet-800 hover:text-violet-600',
    color === 'purple' && 'text-purple-800 hover:text-purple-700',
    color === 'green' && 'text-green-800 hover:text-green-600',
    color === 'teal' && 'text-teal-700 hover:text-teal-600',
    color === 'lime' && 'text-lime-600 hover:text-lime-600',
    color === 'gray' && 'text-slate-400 hover:text-slate-400',
  );
};
