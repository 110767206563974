import { PlatformMultiClientConfigForClient } from '../config/multi-client.config';
import { MultiClientConfigKey } from '../../common/types/multi-client.types';

export const getMultiClientPlatformConfigForClient = (): PlatformMultiClientConfigForClient => {
  const key = (window.env.CLIENT || '') as MultiClientConfigKey;

  if (PlatformMultiClientConfigForClient[key]) {
    return PlatformMultiClientConfigForClient[key];
  }

  throw new Error(`Invalid client key: ${key}`);
};
