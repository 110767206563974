import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

interface Props {
  className?: string;
  children: ReactNode;
}

export const FormRow = ({ className, children }: Props) => {
  return <div className={classNames('mb-psc-base', className)}>{children}</div>;
};
