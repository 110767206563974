import { Button } from './button';
import { BaseButtonProps } from './button.types';

export const ButtonDanger = ({ onClick, disabled, type, className, children }: BaseButtonProps) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      classNameStyle="primary default bg-red-700 text-white hover:bg-red-600 focus:ring-red-700"
      classNameDisabledStyle="primary rounded-full bg-gray-400 text-gray-500 focus:ring-gray-500"
      className={className}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
