import { Fragment, useEffect, useState } from 'react';

import { NavUserInfo } from './nav-user-info';

import { NavDropdownSeparator } from './nav-dropdown-separator';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { classNames } from '../../helpers/classNames';

import { useDoLogout } from '../../hooks/use-do-logout.hook';
import { useRecoilValue } from 'recoil';
import { Menu } from '@headlessui/react';
import { ContactSupportModal } from '../contact-support-modal/ContactSupportModal';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';

const GA_USER_ICON_CLASS = 'ga_nav_user';
const GA_ACCOUNT_CONTACT_SUPPORT_CLASS = 'ga_account_contact_support';
const GA_ACCOUNT_CHANGE_PASSWORD_CLASS = 'ga_account_change_password';
const GA_ACCOUNT_LOGOUT_CLASS = 'ga_account_logout';

export const NavPlatformMenu = () => {
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const logout = useDoLogout({ redirectToLogin: true });
  const currentUser = useRecoilValue(CurrentUserAtom);
  const [version, setVersion] = useState('local');

  const config = getMultiClientPlatformConfigForClient();

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const versionData: { version: string } = require('./version.json');
      setVersion(versionData.version);
    } catch (err) {
      if (process.env.NODE_ENV !== 'development') {
        console.error(err);
      }
    }
  }, []);

  return (
    <>
      <ContactSupportModal open={openContactSupportModal} setOpen={setOpenContactSupportModal} />
      <DropdownMenu
        classNamePosition="right-0 origin-top-right mt-5"
        buttonRender={(open) => (
          <div
            className={classNames(
              'flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-md',
              'hover:bg-blue-100',
              open ? 'bg-slate-200 text-slate-700' : config.topNav.iconColor,
              GA_USER_ICON_CLASS,
            )}
          >
            <i className="fas fa-circle-user fa-xl" />
          </div>
        )}
        items={
          <Fragment>
            <NavUserInfo user={currentUser} />
            <NavDropdownSeparator />
            <div className="py-psc-sm">
              <Menu.Item>
                <a
                  href={window.env.PLATFORM_URL + '/update-password'}
                  className={`
                  block cursor-pointer px-psc-base py-psc-sm text-sm leading-none
                  hover:bg-gray-100 ${GA_ACCOUNT_CHANGE_PASSWORD_CLASS}
                `}
                >
                  Change Password
                </a>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => setOpenContactSupportModal(true)}
                  className={`
                  block w-full cursor-pointer px-psc-base py-psc-sm text-left
                  text-sm leading-none hover:bg-gray-100 ${GA_ACCOUNT_CONTACT_SUPPORT_CLASS}
                `}
                >
                  Contact Support
                </button>
              </Menu.Item>
              <div
                role="button"
                tabIndex={0}
                onClick={() => logout.mutate({})}
                className={`
                block cursor-pointer px-psc-base py-psc-sm text-sm leading-none
                text-red-700 hover:bg-red-50 ${GA_ACCOUNT_LOGOUT_CLASS}
              `}
              >
                Log Out
              </div>
            </div>
            <NavDropdownSeparator />
            <div className="my-psc-base">
              <div className="pl-psc-base text-xs text-gray-500">
                <div>© {new Date().getFullYear()} Payer Sciences</div>
                <div className="mt-psc-sm select-text">Version {version}</div>
              </div>
            </div>
          </Fragment>
        }
      />
    </>
  );
};
