import { ReactNode } from 'react';
import { classNames } from '../../helpers/classNames';

interface Props {
  className?: string;
  children: ReactNode;
}

export const PageContainer = ({ children, className }: Props) => {
  return <div className={classNames(className, 'mt-psc-base px-psc-2xl py-psc-6xl')}>{children}</div>;
};
