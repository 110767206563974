import { classNames } from '../../helpers/classNames';

export interface IconProps {
  icon: string;
  className?: string;
}

export const Icon = ({ icon, className }: IconProps) => {
  return (
    <div
      className={classNames(
        'flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-md',
        className || 'text-gray-600',
      )}
    >
      <i className={icon} />
    </div>
  );
};
