import { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import { FilePondFile, FilePondInitialFile } from 'filepond';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { FormLabel } from '../form/form-label';
import { ButtonDanger } from '../button/button-danger';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  fieldId: string;
  fieldTitle: string;
  refetchImage?: () => void;
  uploadedImageUrl?: string;
  urlToUpload: string;
}

export const ImageUploader = ({
  fieldId,
  fieldTitle,
  refetchImage,
  uploadedImageUrl,
  urlToUpload,
  ...htmlProps
}: Props) => {
  const [files, setFiles] = useState<FilePondFile[]>();
  const [isReplaceButtonClicked, setIsReplaceButtonClicked] = useState(false);

  const onFinishUploadingFile = () => {
    setIsReplaceButtonClicked(false);
    setFiles([]);
    if (refetchImage) {
      refetchImage();
    }
  };

  if (uploadedImageUrl && !isReplaceButtonClicked) {
    return (
      <div {...htmlProps}>
        <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
        <img src={uploadedImageUrl} alt={fieldTitle} />
        <div className={'w-[100%]'}>
          <div className={'m-auto w-[103px] pt-3'}>
            <ButtonDanger onClick={() => setIsReplaceButtonClicked(true)}>Replace</ButtonDanger>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div {...htmlProps}>
      <FormLabel htmlFor={fieldId}>Upload a {fieldTitle}</FormLabel>
      <FilePond
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/*']}
        files={files as unknown as FilePondInitialFile[]}
        onupdatefiles={setFiles}
        onprocessfile={onFinishUploadingFile}
        allowMultiple={false}
        server={urlToUpload}
        name={fieldId}
        labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
};
