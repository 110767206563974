import { AppLogged } from './components/app-logged';

import { createBrowserRouter } from '../../shared/client/helpers/react-router-dom.helpers';
import { ErrorBoundary } from '../../shared/client/components/error-boundary';
import {
  BrandsAdminPage,
  DashboardPage,
  ForgotPasswordPage,
  LogActivityAdminPage,
  Login,
  ResetPasswordPage,
  RoleAdminPage,
  RolesAdminPage,
  UpdatePasswordPage,
  UserAdminPage,
  UsersAdminPage,
} from './lazy-routes';
import { AppNotLogged } from './components/app-not-logged';
import { ProtectedPage } from '../../shared/client/components/protected-page/protected-page';
import { Page404 } from '../../shared/client/pages/page-404/page-404';
import { ConfigAdminPage } from './pages/admin/config/config-admin.page';
import { ADMIN_ROLE } from '../../shared/common/types/roles-permissions.types';

export const router = createBrowserRouter([
  {
    errorElement: <ErrorBoundary />,
    element: <AppLogged />,
    children: [
      { path: '/', element: <DashboardPage /> },
      {
        path: '/admin/users',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <UsersAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/users/:id',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <UserAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/roles',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <RolesAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/roles/:id',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <RoleAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/brands',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <BrandsAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/activity',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <LogActivityAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '/admin/config',
        element: (
          <ProtectedPage roles={[ADMIN_ROLE]}>
            <ConfigAdminPage />
          </ProtectedPage>
        ),
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    errorElement: <ErrorBoundary />,
    element: <AppNotLogged />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/update-password',
        element: <UpdatePasswordPage />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
]);
