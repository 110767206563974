export const SESSION_STORAGE_REDIRECT_TO_URL_KEY = 'urlToRedirect';

export const redirectToUrlIfSetInSession = (defaultAlternativeUrl?: string) => {
  const redirectToUrl = sessionStorage.getItem(SESSION_STORAGE_REDIRECT_TO_URL_KEY);

  if (redirectToUrl) {
    sessionStorage.removeItem(SESSION_STORAGE_REDIRECT_TO_URL_KEY);

    if (window.location.href !== redirectToUrl) {
      window.location.href = redirectToUrl;
    }
  } else if (defaultAlternativeUrl) {
    window.location.href = defaultAlternativeUrl;
  }
};

export const setRedirectToUrlInSession = (url: string) => {
  sessionStorage.setItem(SESSION_STORAGE_REDIRECT_TO_URL_KEY, url);
};
