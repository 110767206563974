import { ReactNode } from 'react';
import { cn } from '../../helpers/classNames';

interface Props {
  children: ReactNode;
  className?: string;
}

export const FormActions = ({ children, className }: Props) => {
  return <div className={cn('flex', className)}>{children}</div>;
};
