import { classNames } from '../../helpers/classNames';
import { Fragment, ReactNode } from 'react';
import logoImage from '../../assets/ps-access-logo.svg';

interface Props {
  url?: string;
  logo: ReactNode;
  altText?: string;
  className?: string;
  isPlatform?: boolean;
  customLogoUrl?: string;
  customLogo?: string | ReactNode;
}

export const NavLogos = ({ logo, isPlatform = false, url, className, customLogo, customLogoUrl }: Props) => {
  if (customLogo) {
    return (
      <div className={classNames('flex h-full items-center justify-center', className)}>
        <a
          href={customLogoUrl || window.env.PLATFORM_URL}
          className="mr-[10px] hidden leading-none text-slate-600 tablet:block"
        >
          {typeof customLogo === 'string' ? <img src={customLogo} alt="" className="h-[33px]" /> : customLogo}
        </a>
      </div>
    );
  }

  return (
    <div className={classNames('flex h-full items-center justify-center pl-psc-base', className)}>
      {!isPlatform ? (
        <Fragment>
          <a className="mr-[10px] hidden leading-none text-slate-600 tablet:block" href={window.env.PLATFORM_URL}>
            <img src={logoImage} alt="" className="h-[33px]" />
          </a>
          <a className="leading-none" href={url}>
            {logo}
          </a>
        </Fragment>
      ) : (
        <a className="leading-none text-slate-600" href={window.env.PLATFORM_URL}>
          <img src={logoImage} className="h-[33px]" alt="" />
        </a>
      )}
    </div>
  );
};
