import { classNames } from '../../helpers/classNames';
import { ButtonProps } from './button.types';

const DEFAULT_BUTTON_STYLE = 'default bg-gray-800 text-white hover:bg-gray-700 focus:ring-gray-700';
const DEFAULT_BUTTON_BASE_STYLE = 'border border-transparent px-5 py-2 text-sm shadow-sm';
const DEFAULT_DISABLED_BUTTON_STYLE = 'default bg-gray-300 text-gray-500 focus:ring-gray-500';

export const Button = ({
  type,
  animate,
  className,
  classNameBase,
  classNameRoundedStyle,
  classNameStyle = DEFAULT_BUTTON_STYLE,
  classNameAnimateStyle = 'bg-green-500',
  classNameDisabledStyle = DEFAULT_DISABLED_BUTTON_STYLE,
  disabled,
  onClick,
  children,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      type={type || 'button'}
      className={classNames(
        'relative inline-flex select-none items-center',
        'font-medium focus:outline-none focus:ring-1 focus:ring-offset-2',
        classNameBase ? classNameBase : DEFAULT_BUTTON_BASE_STYLE,
        classNameRoundedStyle ? classNameRoundedStyle : 'rounded-lg',
        !disabled ? classNameStyle : undefined,
        disabled ? classNameDisabledStyle : undefined,
        disabled && 'disabled',
        className,
      )}
    >
      {animate && (
        <span
          className={classNames(
            'absolute left-0 top-0 -z-10 inline-flex h-full w-full rounded-lg',
            classNameAnimateStyle,
          )}
          style={{ animation: 'button-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite' }}
        />
      )}
      {children}
    </button>
  );
};
