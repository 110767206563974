import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { HandleSubmitEvent } from './form.types';

interface Props {
  id: string;
  children: ReactNode;
  onSubmit: (submitEvent: HandleSubmitEvent) => void;
  className?: string;
}

export const Form = forwardRef(({ id, className, children, onSubmit }: Props, ref?: ForwardedRef<HTMLFormElement>) => {
  return (
    <form
      id={id}
      ref={ref}
      className={className}
      onSubmit={(formEvent) => {
        formEvent.preventDefault();
        onSubmit(formEvent);
      }}
    >
      {children}
    </form>
  );
});

Form.displayName = 'Form';
