import { Fragment, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: string;
}

export const AdminPageSection = ({ children, title }: Props) => {
  return (
    <Fragment>
      <div
        className={
          'relative mb-psc-lg w-[100%] items-center rounded-lg bg-white ' +
          'shadow-[0_2px_4px_rgba(0,0,0,0.2)] transition duration-300 ease-in-out '
        }
      >
        <div
          className={
            'w-[100%] rounded-t-lg border-b-[1px] border-gray-300 bg-gray-50 px-5 py-4 ' +
            'text-sm font-semibold text-gray-900 dark:text-gray-400'
          }
        >
          {title}
        </div>
        <div className={'w-[100%] px-5 py-5'}>{children}</div>
      </div>
    </Fragment>
  );
};
